import './App.css'
import { useEffect, useRef } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import { useTranslation } from 'react-i18next'
import { initializeApp } from 'firebase/app'

import awsconfig from './aws-exports'
import Error404 from './pages/error-404'
import Invitation from './pages/invitation'
import Notification from './components/notification'
import Reach from './pages/reach'
import UnsubscribeReach from './pages/unsubscribe-reach'
import Layout from './components/layout'
import { firebaseConfig, BUILD_ENV, NODE_ENV } from './helpers/utils'
import { NotificationContext } from './context'
import { useI18n } from './hooks/use-i18n'

Amplify.configure({
  ...awsconfig,
  Analytics: { autoSessionRecord: false }
})

// eslint-disable-next-line no-console
console.log({ BUILD_ENV, NODE_ENV })

if (BUILD_ENV === 'production') {
  initializeApp(firebaseConfig)
}

function App() {
  const { t } = useTranslation()
  const { language } = useI18n()

  const notificationRef = useRef(null)

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') return

    if (
      !['develop', 'staging', 'production'].includes(
        process.env.REACT_APP_USER_BRANCH
      )
    ) {
      throw new Error('REACT_APP_USER_BRANCH has a wrong value!')
    }
  }, [])

  const success = (message) => {
    notificationRef.current.alertWithType('success', message)
  }

  const error = (message = t('notification_error_default')) => {
    notificationRef.current.alertWithType('error', message)
  }

  if (!language) return null

  return (
    <>
      <NotificationContext.Provider
        value={{
          success,
          error
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route
              path='/invitation'
              element={
                <Layout>
                  <Invitation />
                </Layout>
              }
            />
            <Route
              path='/reach'
              element={
                <Layout>
                  <Reach />
                </Layout>
              }
            />
            <Route
              path='/unsubscribe/reach/:id'
              element={
                <Layout width='max-w-full'>
                  <UnsubscribeReach />
                </Layout>
              }
            />
            <Route path='*' element={<Error404 />} />
          </Routes>
        </BrowserRouter>
      </NotificationContext.Provider>
      <Notification ref={notificationRef} />
    </>
  )
}

export default App
