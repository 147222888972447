export const updateReachRequest = /* GraphQL */ `
  mutation UpdateReachRequest(
    $input: UpdateReachRequestInput!
    $condition: ModelReachRequestConditionInput
  ) {
    updateReachRequest(input: $input, condition: $condition) {
      id
    }
  }
`

export const updateReachCampaign = /* GraphQL */ `
  mutation UpdateReachCampaign(
    $input: UpdateReachCampaignInput!
    $condition: ModelReachCampaignConditionInput
  ) {
    updateReachCampaign(input: $input, condition: $condition) {
      id
    }
  }
`
