import { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { getSpaceLink } from '../../helpers/space-link'
import { notifyBugsnag } from '../../helpers/bugsnag'
import { NotificationContext } from '../../context'
import Button from '../button'

const CopyToClipboard = ({ space }) => {
  const { t } = useTranslation()
  const { success, error } = useContext(NotificationContext)

  const [copied, setCopied] = useState(false)

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(getSpaceLink(space))

      setCopied(true)
      success(t('invitation.invite_link_copied_success'))
      setTimeout(() => setCopied(false), 1000 * 3)
    } catch (err) {
      const { message } = err

      if (message === 'Write permission denied.') {
        error(t('write_permission_denied_error'))
        return
      }

      error()
      notifyBugsnag(err)
    }
  }

  return (
    <Button.SecondaryXL
      text={t('invitation.copy_invite_link_action')}
      onClick={copyToClipboard}
    />
  )

  // eslint-disable-next-line no-unreachable
  return (
    <div className='flex justify-center'>
      <div
        onClick={copyToClipboard}
        className='flex justify-center items-center rounded-full bg-indigo-600 hover:bg-indigo-500 cursor-pointer px-4 py-1.5'
      >
        <div
          // style={{ background: '#ffffff' }}
          className='flex justify-center items-center cursor-pointer h-6 w-6 rounded-full'
        >
          {copied ? (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5'
              fill='none'
              viewBox='0 0 24 24'
              stroke={'#ffffff'}
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M5 13l4 4L19 7'
              />
            </svg>
          ) : (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5'
              fill='none'
              viewBox='0 0 24 24'
              stroke={'#ffffff'}
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z'
              />
            </svg>
          )}
        </div>
        <span className='ml-1 text-sm font-medium text-white'>
          {t('invitation.copy_invite_link_action')}
        </span>
      </div>
    </div>
  )
}

CopyToClipboard.propTypes = {
  space: PropTypes.object.isRequired
}

export default CopyToClipboard
