import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const ErrorBoundary = ({ error }) => {
  const { t } = useTranslation()

  return (
    <div className='my-48'>
      <div className='text-center'>
        <p className='text-sm font-semibold text-indigo-600 uppercase tracking-wide'>
          {t('error')}
        </p>
        <h1 className='mt-2 text-3xl font-extrabold text-gray-900 tracking-tight'>
          {t('error_title')}
        </h1>
        <p className='mt-4 text-base text-gray-700'>{t(error)}</p>
        <div className='mt-6'>
          <a
            href={t('contact_support_mailto')}
            className='text-base font-medium text-indigo-600 hover:text-indigo-500'
          >
            {t('contact_support_action')}
            <span aria-hidden='true'> &rarr;</span>
          </a>
        </div>
      </div>
    </div>
  )
}

ErrorBoundary.propTypes = {
  error: PropTypes.string.isRequired
}

export default ErrorBoundary
