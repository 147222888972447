import PropTypes from 'prop-types'

import { classNames } from '../helpers/utils'
import Footer from './footer'

const Layout = ({ width, children }) => {
  return (
    <>
      <div className='bg-white px-8 overflow-hidden'>
        <div
          className={classNames(
            'relative mx-auto flex flex-col min-h-screen py-8',
            width
          )}
        >
          <svg
            className='absolute left-full transform translate-x-1/2'
            width={404}
            height={404}
            fill='none'
            viewBox='0 0 404 404'
            aria-hidden='true'
          >
            <defs>
              <pattern
                id='85737c0e-0916-41d7-917f-596dc7edfa27'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={404}
              fill='url(#85737c0e-0916-41d7-917f-596dc7edfa27)'
            />
          </svg>
          <svg
            className='absolute right-full bottom-0 transform -translate-x-1/2'
            width={404}
            height={404}
            fill='none'
            viewBox='0 0 404 404'
            aria-hidden='true'
          >
            <defs>
              <pattern
                id='85737c0e-0916-41d7-917f-596dc7edfa27'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={404}
              fill='url(#85737c0e-0916-41d7-917f-596dc7edfa27)'
            />
          </svg>
          {children}
          <div className='mt-auto'>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  width: PropTypes.string,
  children: PropTypes.node.isRequired
}

Layout.defaultProps = {
  width: 'max-w-xl'
}

export default Layout
