import * as React from 'react'
import { createRoot } from 'react-dom/client'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import './index.css'
import './i18n'
import App from './App'
import Maintenance from './components/maintenance'
import { BUILD_ENV, NODE_ENV } from './helpers/utils'

const { version } = require('../package.json')

Bugsnag.start({
  apiKey: '7c890e68e7fb0f7c5f696dbff265d81e',
  releaseStage: BUILD_ENV,
  plugins: [new BugsnagPluginReact()],
  onError: (event) => {
    if (NODE_ENV === 'development') return false

    const meta = {}
    meta.version = version
    meta.build_env = BUILD_ENV.toUpperCase()
    meta.partner_id = localStorage.getItem('partner-id')
    meta.partner_name = localStorage.getItem('partner-name')
    meta.career_id = localStorage.getItem('career-id')
    meta.partner_user_id = localStorage.getItem('partner-user-id')
    meta.space_id = localStorage.getItem('space-id')

    event.context = `version ${version}`
    event.addMetadata('meta', meta)
  }
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={Maintenance}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
)
