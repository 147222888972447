import { useEffect, useMemo, useState } from 'react'
import { Switch } from '@headlessui/react'
import { API } from 'aws-amplify'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { CheckIcon } from '@heroicons/react/24/solid'
import { isEmpty, isNumber } from 'lodash'

import ActivityIndicator from '../components/activity-indicator'
import Button from '../components/button'
import { useTranslation } from 'react-i18next'
import Input from '../components/input'
import { useQuery } from '../hooks/use-query'
import { classNames, EMAIL_REGEX } from '../helpers/utils'
import { getPartner, getCareer, inviteTalent } from '../graphql/queries'
import { notifyBugsnag } from '../helpers/bugsnag'
import { sendSlackMessage } from '../helpers/slack'
import { getSpaceLink } from '../helpers/space-link'
import CopyToClipboard from '../components/invitation/copy-to-clipboard'
import { localStorage } from '../helpers/local-storage'
import ErrorBoundary from '../components/error-boundary'
import { trackEvent } from '../helpers/analytics'
import SelectMenu from '../components/select-menu'

const FIELDS = {
  firstname: 'firstname',
  lastname: 'lastname',
  email: 'email',
  phone: 'phone',
  postcode: 'postcode',
  work_experience: 'work_experience'
}

// states
const INITIAL = 'INITIAL'
const GET_CAREER = 'GET_CAREER'
const GET_PARTNER = 'GET_PARTNER'
const CHECK_LOCAL_STORAGE = 'CHECK_LOCAL_STORAGE'
const SPACE_CREATED = 'SPACE_CREATED'
const DONE = 'DONE'
const ERROR_GET_CAREER = 'ERROR_GET_CAREER'
const ERROR_NO_CAREER_ID = 'ERROR_NO_CAREER_ID'
const ERROR_CAREER_NOT_ACTIVE = 'ERROR_CAREER_NOT_ACTIVE'
const ERROR_GET_PARTNER = 'ERROR_GET_PARTNER'
const ERROR_SPACE_CREATED = 'ERROR_SPACE_CREATED'

// context
const TALENTPOOL = 'TALENTPOOL'
const SELECT = 'SELECT'
// const ATTRACT = 'ATTRACT'

const Invitation = () => {
  const { t, i18n } = useTranslation()
  const career_id = useQuery('career')
  const partner_id = useQuery('partner')
  const partner_user_id = useQuery('applicant')

  const [state, setState] = useState(INITIAL)
  const [context, setContext] = useState(INITIAL)
  const [career, setCareer] = useState(null)
  const [partner, setPartner] = useState(null)
  const [customLandingPageText, setCustomLandingPageText] = useState(null)
  const [customPrivacyLink, setCustomPrivacyLink] = useState(null)
  const [space, setSpace] = useState(null)
  const [agreed, setAgreed] = useState(false)
  const [fields, setFields] = useState([])
  const [additionalFields, setAdditionalFields] = useState([])
  const [openWindowFailed, setOpenWindowFailed] = useState(false)

  const initialValues = useMemo(() => {
    const result = {
      firstname: fields.includes(FIELDS.firstname) ? '' : undefined,
      lastname: fields.includes(FIELDS.lastname) ? '' : undefined,
      email: fields.includes(FIELDS.email) ? '' : undefined,
      phone: fields.includes(FIELDS.phone) ? '' : undefined,
      postcode: fields.includes(FIELDS.postcode) ? '' : undefined,
      work_experience: fields.includes(FIELDS.work_experience) ? -1 : undefined
    }

    additionalFields.forEach(({ id }) => (result[id] = ''))

    return result
  }, [fields, additionalFields])

  const validationSchema = useMemo(() => {
    const result = {
      firstname: fields.includes(FIELDS.firstname)
        ? Yup.string().required(t('invitation.validation_required'))
        : undefined,
      lastname: fields.includes(FIELDS.lastname)
        ? Yup.string().required(t('invitation.validation_required'))
        : undefined,
      email: fields.includes(FIELDS.email)
        ? Yup.string()
            .matches(EMAIL_REGEX, t('invitation.validation_email_error'))
            .required(t('invitation.validation_required'))
        : undefined,
      phone: fields.includes(FIELDS.phone)
        ? Yup.string()
            .matches(
              /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,9}$/, // eslint-disable-line
              t('invitation.validation_phone_error')
            )
            .required(t('invitation.validation_required'))
        : undefined,
      postcode: fields.includes(FIELDS.postcode)
        ? Yup.string()
            .matches(/\d{5}/, t('invitation.validation_postcode_error'))
            .required(t('invitation.validation_required'))
        : undefined,
      work_experience: fields.includes(FIELDS.work_experience)
        ? Yup.number().min(0).required(t('invitation.validation_required'))
        : undefined
    }

    additionalFields.forEach(({ id, type, required }) => {
      if (type === 'text') {
        result[id] = Yup.string()

        if (required) {
          result[id] = result[id].required(t('invitation.validation_required'))
        }
      }
    })

    return Yup.object(result)
  }, [fields, additionalFields, t])

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const { firstname, lastname, email, phone, postcode, work_experience } =
        values

      try {
        const { location } = window

        let additional_requested_data = {}

        if (isNumber(work_experience) && work_experience !== -1) {
          additional_requested_data.work_experience = work_experience
        }

        additionalFields.forEach(({ id, label }) => {
          additional_requested_data[id] = { id, label, input: values[id] }
        })

        additional_requested_data = !isEmpty(additional_requested_data)
          ? JSON.stringify(additional_requested_data)
          : undefined

        const variables = {
          send_mail: false,
          career_id: career ? career.id : undefined,
          partner_id: partner.id,
          email: email && email.toLowerCase(),
          firstname,
          lastname,
          phone,
          postcode,
          additional_requested_data,
          partner_user_id,
          ref_link: location.href,
          referrer_link: document.referrer
        }

        const blank = window.open('', '_blank')

        const { data } = await API.graphql({
          query: inviteTalent,
          authMode: 'AWS_IAM',
          variables
        })

        try {
          blank.location.href = getSpaceLink(data.inviteTalent)
        } catch (err) {
          setOpenWindowFailed(true)
        }

        setSpace(data.inviteTalent)
        setState(SPACE_CREATED)

        trackEvent('TALENT_INVITED', { partner: partner.id })
        sendSlackMessage(
          'kunden-controlling',
          [
            `pbc.aivy.app - Space ${data.inviteTalent.id}`,
            context === SELECT && `zur Stelle ${career.title}`,
            context === TALENTPOOL && `im Talentpool`,
            `des Partners ${partner.display_name || partner.name}`,
            'wurde erstellt.'
          ]
            .filter((value) => value)
            .join(' ')
        )
      } catch (err) {
        notifyBugsnag(err, 'inviteTalent') || setState(ERROR_SPACE_CREATED)
      }
    }
  })

  useEffect(() => {
    if (state !== GET_CAREER) return

    localStorage.setItem('career-id', career_id)

    if (!career_id) {
      setState(ERROR_NO_CAREER_ID)
      return
    }

    API.graphql({
      query: getCareer,
      authMode: 'AWS_IAM',
      variables: { id: career_id }
    })
      .then(({ data }) => {
        if (!data.getCareer) {
          setState(ERROR_GET_CAREER)
          return
        }
        const language = data.getCareer.language
        if (language) {
          i18n.changeLanguage(language)
        }

        if (data.getCareer.status !== 'ACTIVE' || data.getCareer.archived) {
          setState(ERROR_CAREER_NOT_ACTIVE)
          return
        }

        setState(GET_PARTNER)
        setCareer(data.getCareer)
      })
      .catch(
        (err) => notifyBugsnag(err, 'getCareer') || setState(ERROR_GET_CAREER)
      )
  }, [state, career_id, i18n])

  useEffect(() => {
    if (state !== GET_PARTNER) return

    API.graphql({
      query: getPartner,
      authMode: 'AWS_IAM',
      variables: { id: partner_id || career.partner_id }
    })
      .then(({ data }) => {
        if (!data.getPartner) {
          setState(ERROR_GET_PARTNER)
          return
        }

        localStorage.setItem('partner-id', data.getPartner.id)
        localStorage.setItem('partner-name', data.getPartner.name)
        localStorage.setItem('partner-user-id', partner_user_id)

        setPartner(data.getPartner)
        setState(CHECK_LOCAL_STORAGE)
      })
      .catch(
        (err) => notifyBugsnag(err, 'getPartner') || setState(ERROR_GET_PARTNER)
      )
  }, [state, career, partner_id, partner_user_id])

  useEffect(() => {
    if (state !== CHECK_LOCAL_STORAGE) return

    const invitations = JSON.parse(localStorage.getItem('invitations') || '{}')

    if (context === SELECT && !partner_user_id && invitations[career_id]) {
      setSpace({ id: invitations[career_id] })
      setState(SPACE_CREATED)
      return
    }

    if (context === TALENTPOOL && !partner_user_id && invitations[partner_id]) {
      setSpace({ id: invitations[partner_id] })
      setState(SPACE_CREATED)
      return
    }

    setState(DONE)
  }, [state, context, career_id, partner_id, partner_user_id])

  useEffect(() => {
    if (state !== SPACE_CREATED) return

    const invitations = JSON.parse(localStorage.getItem('invitations') || '{}')

    if (context === SELECT && !partner_user_id) {
      invitations[career_id] = space.id
    }

    if (context === TALENTPOOL && !partner_user_id) {
      invitations[partner_id] = space.id
    }

    localStorage.setItem('space-id', space.id)
    localStorage.setItem('invitations', JSON.stringify(invitations))
  }, [state, context, career_id, partner_id, partner_user_id, space])

  useEffect(() => {
    if (partner_id) {
      setContext(TALENTPOOL)
      setState(GET_PARTNER)
      return
    }

    if (career_id) {
      setContext(SELECT)
      setState(GET_CAREER)
      return
    }

    setState(ERROR_NO_CAREER_ID)
  }, [career_id, partner_id])

  useEffect(() => {
    if (![DONE, SPACE_CREATED].includes(state)) return

    // [TALENTPOOL, SELECT].includes(context)
    const partnerComponents = JSON.parse(
      partner.app_settings?.components || '{}'
    )

    let careerComponents = {}
    let application_input_additional_fields = []
    if (context === SELECT) {
      careerComponents = JSON.parse(career.app_settings?.components || '{}')
      application_input_additional_fields = JSON.parse(
        career.app_settings?.application_input_additional_fields || '[]'
      )
    }

    setCustomPrivacyLink(partnerComponents.customPrivacyLink)
    setCustomLandingPageText(
      careerComponents.customLandingPageText ||
        partnerComponents.customLandingPageText
    )
    setFields(
      careerComponents.customLandingPageFields ||
        partnerComponents.customLandingPageFields ||
        Object.keys(FIELDS)
    )
    setAdditionalFields(application_input_additional_fields)
  }, [state, context, partner, career])

  if ([INITIAL, GET_CAREER, GET_PARTNER, CHECK_LOCAL_STORAGE].includes(state)) {
    return (
      <div className='flex justify-center pt-64'>
        <ActivityIndicator />
      </div>
    )
  }

  return (
    <>
      {[DONE, SPACE_CREATED].includes(state) && (
        <>
          <img
            className='mx-auto w-32 h-32 object-contain'
            src={`${
              career?.logo || partner.logo
            }?random_number=${new Date().getTime()}`}
            alt=''
          />
          <div className='mt-4 text-center'>
            {context === SELECT && (
              <>
                <h2 className='text-2xl font-bold text-gray-900'>
                  {career.title}
                </h2>
                <p className='text-lg text-gray-500 italic'>
                  {partner.display_name || partner.name}
                </p>
              </>
            )}
            {context === TALENTPOOL && (
              <h2 className='text-2xl font-bold text-gray-900'>
                {partner.display_name || partner.name}
              </h2>
            )}
          </div>
        </>
      )}

      {[
        ERROR_NO_CAREER_ID,
        ERROR_GET_CAREER,
        ERROR_CAREER_NOT_ACTIVE,
        ERROR_GET_PARTNER,
        ERROR_SPACE_CREATED
      ].includes(state) && <ErrorBoundary error={state} />}

      {state === DONE && (
        <div className='mt-4'>
          {(customLandingPageText || '').split('\n').map((paragraph, index) => (
            <p key={index} className='mb-4 text-md text-gray-700 text-center'>
              {paragraph}
            </p>
          ))}
          <form
            onSubmit={formik.handleSubmit}
            className='mt-8 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8'
          >
            {[
              [
                'firstname',
                t('invitation.firstname_label'),
                t('invitation.firstname_placeholder'),
                '',
                true
              ],
              [
                'lastname',
                t('invitation.lastname_label'),
                t('invitation.lastname_placeholder'),
                '',
                true
              ],
              [
                'email',
                t('invitation.email_label'),
                t('invitation.email_placeholder'),
                'sm:col-span-2',
                true
              ],
              [
                'postcode',
                t('invitation.postcode_label'),
                t('invitation.postcode_placeholder'),
                '',
                false
              ],
              [
                'phone',
                t('invitation.phone_label'),
                t('invitation.phone_placeholder'),
                'sm:col-span-2',
                true
              ]
            ]
              .filter(([id]) => fields.includes(id))
              .map((input, index) => (
                <div key={index} className={input[3]}>
                  <Input
                    type='text'
                    id={input[0]}
                    label={input[1]}
                    placeholder={input[2]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values[input[0]] || ''}
                    touched={formik.touched[input[0]]}
                    error={formik.errors[input[0]]}
                    disabled={formik.isSubmitting}
                    inlineErrorMessage={input[4]}
                  />
                </div>
              ))}
            {fields.includes(FIELDS.work_experience) && (
              <div className='sm:col-span-2'>
                <SelectMenu
                  id='work_experience'
                  label={t(
                    `invitation.work_experience.${
                      career_id ? 'career' : 'partner'
                    }_label`
                  )}
                  options={[
                    [-1, t('invitation.work_experience.select')],
                    [0, t('invitation.work_experience.starter')],
                    [1, t('invitation.work_experience.junior')],
                    [2, t('invitation.work_experience.mid_level')],
                    [3, t('invitation.work_experience.senior')]
                  ]}
                  onChange={(value) =>
                    formik.setFieldValue('work_experience', value)
                  }
                  defaultValue={formik.values.work_experience || -1}
                  translate={false}
                />
              </div>
            )}
            {additionalFields.map(({ id, label, placeholder }, index) => (
              <div key={index} className='sm:col-span-2'>
                <Input
                  type='text'
                  id={id}
                  label={label || t('invitation.no_label')}
                  placeholder={placeholder}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[id] || ''}
                  touched={formik.touched[id]}
                  error={formik.errors[id]}
                  disabled={formik.isSubmitting}
                />
              </div>
            ))}
            <div className='sm:col-span-2 mt-4'>
              <div className='flex items-start'>
                <div className='flex-shrink-0'>
                  <Switch
                    checked={agreed}
                    onChange={setAgreed}
                    className={classNames(
                      agreed ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    )}
                  >
                    <span className='sr-only'>Agree to policies</span>
                    <span
                      aria-hidden='true'
                      className={classNames(
                        agreed ? 'translate-x-5' : 'translate-x-0',
                        'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                      )}
                    />
                  </Switch>
                </div>
                <div className='ml-3'>
                  <p className='text-sm text-gray-700'>
                    {`${t('datapolicy.description.first')} `}
                    <a
                      href={
                        customPrivacyLink || 'https://aivy.app/datenschutz/'
                      }
                      className='font-medium text-gray-900 underline'
                      target='_blank'
                      rel='noreferrer'
                    >
                      {t('datapolicy_alt')}
                    </a>{' '}
                    {`${t('datapolicy.description.second', {
                      partnerName: partner.name
                    })} `}
                  </p>
                </div>
              </div>
            </div>
            <div className='mt-8 sm:col-span-2 flex justify-end'>
              <Button.PrimaryXL
                text={t('invitation.submit_action')}
                disabled={!(formik.isValid && agreed)}
                onClick={formik.handleSubmit}
                isLoading={formik.isSubmitting}
              />
            </div>
          </form>
        </div>
      )}

      {state === SPACE_CREATED && (
        <>
          <div className='mt-8 mx-auto max-w-sm'>
            <div className='mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-100'>
              <CheckIcon
                className='h-12 w-12 text-green-600'
                aria-hidden='true'
              />
            </div>
            <div className='mt-8 text-center'>
              <h3 className='text-lg leading-6 font-medium text-gray-900'>
                {t('invitation.space_created_success_title')}
              </h3>
              <div className='mt-2'>
                <p className='text-sm text-gray-700'>
                  {t('invitation.space_created_success_description')}
                </p>
              </div>
              <div className='mt-12 flex flex-col'>
                {!openWindowFailed && (
                  <div>
                    <span className='block text-sm italic text-gray-700'>
                      {t('invitation.redirect_info')}
                    </span>
                    <span className='block mt-2 text-sm text-gray-700'>
                      {t('invitation.manually_redirect_1')}
                      <a
                        id='anchor-to-aivy'
                        href={getSpaceLink(space)}
                        target='_blank'
                        rel='noreferrer'
                        className='text-indigo-600 underline hover:text-indigo-700'
                      >
                        {t('invitation.manually_redirect_2')}
                      </a>
                      {t('invitation.manually_redirect_3')}
                    </span>
                  </div>
                )}
                <div className='mt-4 flex justify-center gap-x-2'>
                  {openWindowFailed && (
                    <Button.PrimaryXL
                      text={t('invitation.open_invite_link_action')}
                      onClick={() => window.open(getSpaceLink(space), '_blank')}
                    />
                  )}
                  <CopyToClipboard space={space} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Invitation
