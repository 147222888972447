import { notifyBugsnag } from './bugsnag'
import { NODE_ENV } from './utils'

const channels = {
  _bugsnag:
    'https://hooks.slack.com/services/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',
  vertrieb:
    'https://hooks.slack.com/services/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',
  'kunden-controlling':
    'https://hooks.slack.com/services/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'
}

function _sendSlackMessage(channel, message) {
  return window.fetch(channels[channel], {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: JSON.stringify({ text: message })
  })
}

export async function sendSlackMessage(channel, message, throwError) {
  // because of cors policy
  if (NODE_ENV === 'development') {
    return console.log('send-slack-message:', message) // eslint-disable-line
  }

  try {
    await _sendSlackMessage(channel, message)
  } catch (err) {
    notifyBugsnag(err)

    if (throwError) {
      throw err
    }
  }
}
