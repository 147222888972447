import { useTranslation } from 'react-i18next'

import AivyIcon from '../assets/images/aivyicon.svg'
import { classNames } from '../helpers/utils'

const Maintenance = () => {
  const { t } = useTranslation()

  return (
    <>
      <div
        className={classNames(
          'flex min-h-full flex-col bg-white',
          // 'border-y-4 border-gray-200',
          'mt-24 pt-16 pb-12'
        )}
      >
        <main className='mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8'>
          <div className='flex flex-shrink-0 justify-center'>
            <a href='/' className='inline-flex'>
              <span className='sr-only'>aivy-icon</span>
              <img className='h-16 w-auto' src={AivyIcon} alt='' />
            </a>
          </div>
          <div className='pb-16 pt-8'>
            <div className='flex flex-col items-center text-center'>
              <h1
                className={classNames(
                  'mt-2 font-bold tracking-tight text-gray-900',
                  'text-4xl sm:text-5xl'
                )}
              >
                {t('error_unhandled.title')}
              </h1>
              <p className='mt-4 max-w-md text-base text-gray-900'>
                {t('error_unhandled.message')}
              </p>
              {/* <div className='mt-6'>
                  <a
                    href='/'
                    className='text-base font-medium text-indigo-600 hover:text-indigo-500'
                  >
                    {t('error_unhandled.go_to_home')}
                    <span aria-hidden='true'> &rarr;</span>
                  </a>
                </div> */}
            </div>
          </div>
        </main>

        <footer className='mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8'>
          <nav className='flex justify-center space-x-4'>
            <a
              href='mailto:help@aivy.app?subject=[SUPPORT - PUBLIC_APP]'
              className='text-sm font-medium text-indigo-500 hover:text-indigo-600'
            >
              {t('error_unhandled.contact_support')}
            </a>
            {/* <span
              className='inline-block border-l border-gray-300'
              aria-hidden='true'
            />
            <span
              className='inline-block border-l border-gray-300'
              aria-hidden='true'
            />
            <a
              href='#'
              className='text-sm font-medium text-gray-500 hover:text-gray-600'
            >
              Twitter
            </a> */}
          </nav>
        </footer>
      </div>
    </>
  )
}

export default Maintenance
