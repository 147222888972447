import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useMount } from './use-mount'
import { notifyBugsnag } from '../helpers/bugsnag'

const DE = 'de'
const EN = 'en'
const languages = [DE, EN]

const isLanguageAvailable = (language) => {
  return languages.includes(language)
}

export const useI18n = () => {
  const { i18n } = useTranslation()

  const [currentLang, setCurrentLang] = useState(null)

  useMount(() => {
    const getActiveLanguage = () => {
      try {
        const searchParams = new URLSearchParams(document.location.search)
        const languageFromUrl = searchParams.get('language')

        if (languageFromUrl) return languageFromUrl

        // browser / device language
        const navigatorLanguage = navigator.language.split('-').shift()
        if (isLanguageAvailable(navigatorLanguage)) return navigatorLanguage
      } catch (err) {
        notifyBugsnag(err)
      }

      return EN
    }

    const language = getActiveLanguage()

    setCurrentLang(language)
    i18n.changeLanguage(language)
  })

  return { language: currentLang }
}
