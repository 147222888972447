import { API } from 'aws-amplify'
import { useState } from 'react'
import { useParams } from 'react-router'

import { pinpointControl } from '../graphql/queries'
import ActivityIndicator from '../components/activity-indicator'

export default function ReachMessages() {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [unsubscribed, setUnsubscribed] = useState(false)

  async function unsubscribeFromTopic() {
    setIsLoading(true)
    try {
      await API.graphql({
        query: pinpointControl,
        variables: {
          user_id: id,
          action: 'unsubscribeEndpointFromTopic',
          topic: 'REACH'
        },
        authMode: 'AWS_IAM'
      })
      // console.log(JSON.parse(res.data.pinpointControl))
      setIsLoading(false)
      setUnsubscribed(true)
    } catch (error) {
      error.errors.map((error) => {
        // console.log(error)
        alert(error.message)
        return error
      })
      setIsLoading(false)
    }
  }

  if (unsubscribed) {
    return (
      <main
        className='min-h-full bg-cover bg-top sm:bg-top h-screen'
        style={{
          backgroundImage:
            'url("https://images.unsplash.com/photo-1489641024260-20e5cb3ee4aa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2066&q=80")'
        }}
      >
        <div className='max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48'>
          <h1 className='mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl'>
            Viel Erfolg auf deiner weiteren Reise!
          </h1>
          <p className='mt-2 text-lg font-medium text-black text-opacity-50'>
            Viel Erfolg auf deiner weiteren Reise!
          </p>
          <div className='mt-6'>
            <a
              href='https://aivy.app'
              className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-white bg-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50'
            >
              Heimweg antreten
            </a>
          </div>
        </div>
      </main>
    )
  }

  return (
    <div className='bg-gray-50'>
      <main className='lg:relative'>
        <div className='mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left'>
          <div className='px-4 lg:w-1/2 sm:px-8 xl:pr-16'>
            <h1 className='text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl'>
              <span className='block xl:inline'>
                Berufsvorschlag hat nicht gepasst?
              </span>{' '}
            </h1>
            <p className='mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl'>
              DONT WASE YOUR TALENT - Wir haben täglich neue Berufe die wir mit
              Experten aus den jeweiligen Unternehmen einschätzen und
              psychologische Profile erstellen. Sollte dein Vorschlag nicht
              gepasst haben, kannst du weitere Daten hinterelgen und somit
              selbst Einfluss auf die Vorschläge nehmen.
            </p>
            <div className='mt-10 sm:flex sm:justify-center lg:justify-start'>
              <div className='rounded-md shadow'>
                <a
                  href='https://webapp.aivy.app'
                  className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10'
                >
                  Filter hinterelgen
                </a>
              </div>
              <div className='mt-3 rounded-md shadow sm:mt-0 sm:ml-3'>
                {isLoading ? (
                  <ActivityIndicator></ActivityIndicator>
                ) : (
                  <a
                    onClick={() => unsubscribeFromTopic()}
                    className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10'
                  >
                    Keine Vorschläge erhalten
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full'>
          <img
            className='absolute inset-0 w-full h-full object-cover'
            src='https://images.unsplash.com/photo-1584921466621-d3a283cd3744?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2148&q=80'
            alt=''
          />
        </div>
      </main>
      <div className='bg-white pt-16 lg:py-24'>
        <div className='pb-16 bg-indigo-600 lg:pb-0 lg:z-10 lg:relative'>
          <div className='lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8'>
            <div className='relative lg:-my-8'>
              <div
                aria-hidden='true'
                className='absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden'
              />
              <div className='mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full'>
                <div className='aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full'>
                  <img
                    className='object-cover lg:h-full lg:w-full'
                    src='https://images.unsplash.com/photo-1543060829-a0029874b174?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80'
                    alt=''
                  />
                </div>
              </div>
            </div>
            <div className='mt-12 lg:m-0 lg:col-span-2 lg:pl-8'>
              <div className='mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none'>
                <blockquote>
                  <div>
                    <svg
                      className='h-12 w-12 text-white opacity-25'
                      fill='currentColor'
                      viewBox='0 0 32 32'
                      aria-hidden='true'
                    >
                      <path d='M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z' />
                    </svg>
                    <p className='mt-6 text-2xl font-medium text-white'>
                      Als Quereinsteiger habe ich endlich nach 6 Jahren
                      unzufriedenheit einen Beruf gefunden, indem ich meine
                      Stärken weiterentwicklen kann und meine Schwächen werden
                      vom Rest des Teams perfekt aufgefangen. Danke Aivy!
                    </p>
                  </div>
                  <footer className='mt-6'>
                    <p className='text-base font-medium text-white'>
                      Joel Misomat
                    </p>
                    <p className='text-base font-medium text-indigo-100'>
                      Designer @Speak
                    </p>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
