import { useTranslation } from 'react-i18next'

import logo from '../assets/images/logo-alt.png'

const Footer = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className='mt-12 mb-2 w-full border-t border-dashed border-gray-200' />
      <div className='flex justify-between items-center text-sm text-gray-900'>
        <div className='items-center flex-row'>
          <a
            href='https://www.aivy.app/impressum'
            target='_blank'
            rel='noreferrer'
          >
            {t('imprint')}
          </a>
          <a
            href='https://www.aivy.app/datenschutz'
            target='_blank'
            rel='noreferrer'
            className='ml-4'
          >
            {t('datapolicy')}
          </a>
        </div>
        <div
          className='flex items-center cursor-pointer'
          onClick={() => window.open('https://aivy.app')}
        >
          <p className='hidden sm:block italic whitespace-nowrap'>
            {t('powered_by')}
          </p>
          <img src={logo} alt='' className='ml-4 w-24 h-12 object-contain' />
        </div>
      </div>
    </>
  )
}

export default Footer
