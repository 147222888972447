import { BUILD_ENV } from './utils'

export const getSpaceLink = ({ id, language }) => {
  const params = `?invitation=${id}&language=${language || 'de'}`

  switch (BUILD_ENV) {
    case 'develop':
      return `http://develop.webapp.aivy.app.s3-website.eu-central-1.amazonaws.com/${params}`
    case 'staging':
      return `http://staging.webapp.aivy.app.s3-website.eu-central-1.amazonaws.com/${params}`
    case 'production':
      return `https://webapp.aivy.app/${params}`
    default:
      return `http://develop.webapp.aivy.app.s3-website.eu-central-1.amazonaws.com/${params}`
  }
}
