export const BUILD_ENV = process.env.REACT_APP_USER_BRANCH || 'develop'
export const NODE_ENV = process.env.NODE_ENV

export const EMAIL_REGEX =
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

export const firebaseConfig = {
  apiKey: 'AIzaSyAIQRm8DnTc7_EVqP315qeiCtneJt9GW30',
  authDomain: 'aivy-public-app.firebaseapp.com',
  projectId: 'aivy-public-app',
  storageBucket: 'aivy-public-app.appspot.com',
  messagingSenderId: '884802532586',
  appId: '1:884802532586:web:a3c7505ed52db44a729393',
  measurementId: 'G-7TGWEDKNPG'
}
