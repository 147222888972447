import Bugsnag from '@bugsnag/js'
import { NODE_ENV } from './utils'
import { isError } from 'lodash'

const convertGraphqlError = ({ errors }, { query }) => {
  if (
    // graphql error
    Array.isArray(errors)
  ) {
    const { message, path } = errors[0]
    const error_path = path && path[0] !== query && path[0]

    return new Error(
      [query, message, error_path, '(graphql error)']
        .filter((item) => item)
        .join(' / ')
    )
  }

  return new Error('convertGraphqlError: unknown')
}

export const notifyBugsnag = (error, query) => {
  console.error(error) // eslint-disable-line

  if (NODE_ENV === 'development') return

  const shadow = isError(error) ? error : convertGraphqlError(error, { query })

  const { message } = shadow
  if (
    message.toLowerCase().includes('failed to fetch') ||
    message.toLowerCase().includes('network error') ||
    message.toLowerCase().includes('networkerror')
  ) {
    return
  }

  Bugsnag.notify(shadow)
}
