import { notifyBugsnag } from './bugsnag'

const setItem = (key, value) => {
  try {
    typeof window !== 'undefined' && window.localStorage.setItem(key, value)
  } catch (err) {
    // if (err.name === 'QuotaExceededError') {
    //  return
    // }

    notifyBugsnag(err)
  }
}

const getItem = (key) => {
  try {
    return typeof window !== 'undefined' && window.localStorage.getItem(key)
  } catch (err) {
    notifyBugsnag(err)
  }
}

const clear = () => {
  const invitations = getItem('invitations')

  try {
    typeof window !== 'undefined' && window.localStorage.clear()
    setItem('invitations', invitations) // persist invitations
  } catch (err) {
    notifyBugsnag(err)
  }
}

export const localStorage = {
  setItem,
  getItem,
  clear
}
