import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'

import { useQuery } from '../hooks/use-query'
import { notifyBugsnag } from '../helpers/bugsnag'
import ActivityIndicator from '../components/activity-indicator'
import AivyIcon from '../assets/images/aivyicon.svg'
import { getReachCampaign, getReachRequest } from '../graphql/queries'
import { updateReachCampaign, updateReachRequest } from '../graphql/mutations'
import ErrorBoundary from '../components/error-boundary'

const Reach = () => {
  const campaign = useQuery('campaign')
  const reachrequest = useQuery('reachrequest')

  const [state, setState] = useState('INITIAL')
  const [reachCampaign, setReachCampaign] = useState(null)
  const [reachRequest, setReachRequest] = useState(null)

  useEffect(() => {
    if (state !== 'GET_REACH_REQUEST') return

    API.graphql({
      query: getReachRequest,
      authMode: 'AWS_IAM',
      variables: { id: reachrequest }
    })
      .then(({ data }) => {
        if (!data.getReachRequest) {
          setState('ERROR_GET_REACH_REQUEST')
          notifyBugsnag(new Error('ERROR_GET_REACH_REQUEST'))
          return
        }

        setReachRequest(data.getReachRequest)
        setState('GET_REACH_CAMPAIGN')
      })
      .catch((err) => notifyBugsnag(err) || setState('ERROR_GET_REACH_REQUEST'))
  }, [state, reachrequest])

  useEffect(() => {
    if (state !== 'GET_REACH_CAMPAIGN') return

    API.graphql({
      query: getReachCampaign,
      authMode: 'AWS_IAM',
      variables: { id: campaign }
    })
      .then(({ data }) => {
        if (!data.getReachCampaign) {
          setState('ERROR_GET_REACH_CAMPAIGN')
          notifyBugsnag(new Error('ERROR_GET_REACH_CAMPAIGN'))
          return
        }

        setReachCampaign(data.getReachCampaign)
        setState('UPDATE_ITEMS')
      })
      .catch(
        (err) => notifyBugsnag(err) || setState('ERROR_GET_REACH_CAMPAIGN')
      )
  }, [state, campaign])

  useEffect(() => {
    if (state !== 'UPDATE_ITEMS') return

    Promise.all(
      [
        reachRequest &&
          API.graphql({
            query: updateReachRequest,
            authMode: 'AWS_IAM',
            variables: {
              input: {
                id: reachRequest.id,
                clicked: new Date().toISOString()
              }
            }
          }),
        API.graphql({
          query: updateReachCampaign,
          authMode: 'AWS_IAM',
          variables: {
            input: {
              id: reachCampaign.id,
              mail_campaign_link_clicked_count:
                (reachCampaign.mail_campaign_link_clicked_count || 0) + 1
            }
          }
        })
      ].filter((request) => request)
    )
      .catch((err) => notifyBugsnag(err))
      .finally(() => setState('DONE'))
  }, [state, reachCampaign, reachRequest])

  useEffect(() => {
    if (state !== 'DONE') return

    if (!reachCampaign.external_url) {
      notifyBugsnag(new Error(`external_url is missing on ${reachCampaign.id}`))
      setState('ERROR_NO_EXTERNAL_URL')

      return
    }

    window.location.replace(reachCampaign.external_url)
  }, [state, reachCampaign])

  useEffect(() => {
    if (state !== 'START') return

    if (reachrequest) {
      setState('GET_REACH_REQUEST')
      return
    }

    if (campaign) {
      setState('GET_REACH_CAMPAIGN')
      return
    }

    setState('ERROR_WRONG_LINK')
  }, [state, reachrequest, campaign])

  useEffect(() => setState('START'), [])

  if (
    ['INITIAL', 'GET_REACH_CAMPAIGN', 'UPDATE_ITEMS', 'DONE'].includes(state)
  ) {
    return (
      <div className='flex flex-col items-center pt-32'>
        <img className='h-32 w-auto' src={AivyIcon} alt='Workflow' />
        {state !== 'DONE' && (
          <div className='mt-16'>
            <ActivityIndicator />
          </div>
        )}
      </div>
    )
  }

  if (
    [
      'ERROR_WRONG_LINK',
      'ERROR_GET_REACH_REQUEST',
      'ERROR_GET_REACH_CAMPAIGN',
      'ERROR_NO_EXTERNAL_URL'
    ].includes(state)
  ) {
    return <ErrorBoundary error={state} />
  }

  return null
}

export default Reach
