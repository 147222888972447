import { getAnalytics, logEvent } from 'firebase/analytics'

import { BUILD_ENV } from './utils'

export const trackEvent = (eventName, eventParams) => {
  if (BUILD_ENV !== 'production') {
    console.log('trackEvent', { eventName, eventParams }) // eslint-disable-line
    return
  }

  const analytics = getAnalytics()
  logEvent(analytics, eventName, eventParams)
}
