export const getPartner = /* GraphQL */ `
  query GetPartner($id: ID!) {
    getPartner(id: $id) {
      id
      name
      display_name
      logo
      app_settings {
        components
      }
    }
  }
`

export const getCareer = /* GraphQL */ `
  query GetCareer($id: ID!) {
    getCareer(id: $id) {
      id
      title
      partner_id
      status
      language
      archived
      logo
      app_settings {
        components
        application_input_additional_fields
      }
    }
  }
`

export const pinpointControl = /* GraphQL */ `
  query PinpointControl(
    $action: String!
    $channel_type: CHANNEL_TYPES
    $user_id: ID!
    $topic: PinpointTopics
    $address: String
    $attributes: AWSJSON
    $location: PinpointControlLocationInput
    $demographic: PinpointControlDemographicInput
  ) {
    pinpointControl(
      action: $action
      channel_type: $channel_type
      user_id: $user_id
      topic: $topic
      address: $address
      attributes: $attributes
      location: $location
      demographic: $demographic
    )
  }
`

export const inviteTalent = /* GraphQL */ `
  query InviteTalent(
    $send_mail: Boolean
    $ref_link: String
    $referrer_link: String
    $career_id: ID
    $partner_id: ID
    $partner_user_id: ID
    $firstname: String
    $lastname: String
    $email: String
    $phone: String
    $postcode: String
    $additional_requested_data: AWSJSON
  ) {
    inviteTalent(
      send_mail: $send_mail
      ref_link: $ref_link
      referrer_link: $referrer_link
      career_id: $career_id
      partner_id: $partner_id
      partner_user_id: $partner_user_id
      firstname: $firstname
      lastname: $lastname
      email: $email
      phone: $phone
      postcode: $postcode
      additional_requested_data: $additional_requested_data
    ) {
      id
    }
  }
`

export const getReachRequest = /* GraphQL */ `
  query GetReachRequest($id: ID!) {
    getReachRequest(id: $id) {
      id
      campaign_id
    }
  }
`

export const getReachCampaign = /* GraphQL */ `
  query GetReachCampaign($id: ID!) {
    getReachCampaign(id: $id) {
      id
      external_url
      mail_campaign_link_clicked_count
    }
  }
`
